import React from "react"
import styled from "styled-components"

import { betweenD, maxD } from "../../assets/styles/helpers/devices"
import { isLinkAnAnchor } from "../../assets/styles/helpers/common"

import { LineWrapperWithBottomSpace } from "../global/common/containers"
import { SecondaryTitle } from "../global/common/text"
import { Button } from "../global/atoms/Button"
import { greenBox } from "../global/common/ornaments"

const Divider = styled.figure`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 40px 120px;
  background-color: var(--dark-gray);

  ${greenBox}

  &::after {
    left: 20px;
    bottom: -15px;
  }

  @media ${maxD.laptop} {
    padding: 40px;
  }

  @media ${betweenD.tabletLaptop} {
    &::after {
      bottom: -40px;
    }
  }

  @media ${maxD.tablet} {
    flex-direction: column;
  }
`
const DividerTitle = styled(SecondaryTitle)`
  color: #fff;
  padding: 0 80px 0 0;

  @media ${maxD.laptop} {
    padding-right: 0 1.75rem 0 0;
  }

  @media ${maxD.tablet} {
    text-align: center;
    padding: 0 0 2rem;
  }
`

const HourWorkDivider = ({ data }) => {
  const { dividerText: title, dividerButton: button } = data

  return (
    <LineWrapperWithBottomSpace
      style={{
        "--does-bold-line-exists": "none",
      }}
    >
      <Divider>
        <DividerTitle
          dangerouslySetInnerHTML={{ __html: title }}
          style={{ "--spacing-bottom": "0" }}
        />
        {button && button.url && (
          <Button
            theme="green"
            target={button.target}
            anchor={isLinkAnAnchor(button.url)}
            to={button.url}
            containerStyle={{ "--padding-horizontal": "3rem" }}
          >
            {button.title}
          </Button>
        )}
      </Divider>
    </LineWrapperWithBottomSpace>
  )
}

export default HourWorkDivider
