import React from "react"
import styled from "styled-components"

import LiteYouTubeEmbed from "react-lite-youtube-embed"

import { minD, maxD } from "../../assets/styles/helpers/devices"

import { GrayContainer } from "../global/common/containers"
import { MainTitle, SecondaryTitle } from "../global/common/text"

import { Signature } from "../global/atoms/Signature"

import { WhyUsRightContentWrapper } from "../global/atoms/why-us-movie/Wrapper"
import { WhyUsContent } from "../global/atoms/why-us-movie/Content"
import { WhyUsSubtitle } from "../global/atoms/why-us-movie/Subtitle"
import { WhyUsMovie } from "../global/atoms/why-us-movie/Movie"
import { WhyUsMovieContainer } from "../global/atoms/why-us-movie/MovieContainer"
import { WhyUsSignatureContainer } from "../global/atoms/why-us-movie/SignatureContainer"
import { WhyUsLogo } from "../global/atoms/why-us-movie/Logo"

const WhyUsCustomContent = styled(WhyUsContent)`
  @media ${minD.tabletL} {
    padding-right: 120px;
  }
`

const WhyUsMovieCustomContainer = styled(WhyUsMovieContainer)`
  &::after {
    right: 20px;

    @media ${maxD.tablet} {
      right: -40px;
    }
  }

  &::before {
    left: -25px;
  }
`

const WhyUs = ({ data }) => {
  const {
    whyUsTitle: title,
    whyUsTextBelowTitle: textUnderTheTitle,
    whyUsSubtitle: subTitle,
    whyUsTextBelowSubtitle: description,
    offerWhyUsMovie: movieID,
    testimonialAuthorName: authorName,
    testimonialAuthorPosition: authorPosition,
    testimonialAuthorLogo: logoObject,
  } = data

  const logo = {
    url: logoObject?.localFile.publicURL,
    alt: logoObject?.altText,
  }

  return (
    <GrayContainer>
      <WhyUsRightContentWrapper flex alignItems="fs">
        <WhyUsCustomContent>
          <MainTitle dangerouslySetInnerHTML={{ __html: title }} />
          <WhyUsSubtitle
            dangerouslySetInnerHTML={{ __html: textUnderTheTitle }}
          />
          <SecondaryTitle
            dangerouslySetInnerHTML={{ __html: subTitle }}
            as="h3"
          />
          <p dangerouslySetInnerHTML={{ __html: description }} />
        </WhyUsCustomContent>
        <WhyUsMovie>
          <WhyUsMovieCustomContainer>
            <LiteYouTubeEmbed
              id={movieID}
              poster="maxresdefault"
              title="Client words on video"
              noCookie={true}
            />
          </WhyUsMovieCustomContainer>
          <WhyUsSignatureContainer>
            <Signature
              name={authorName}
              position={authorPosition}
              style={{ "--padding-mobile": "2.5rem 0 " }}
            />
            {logo && <WhyUsLogo src={logo.url} alt={logo.alt} />}
          </WhyUsSignatureContainer>
        </WhyUsMovie>
      </WhyUsRightContentWrapper>
    </GrayContainer>
  )
}

export default WhyUs
