import React from "react"
import styled from "styled-components"

import { maxD } from "../../assets/styles/helpers/devices"

import { LineWrapperWithBottomSpace } from "../global/common/containers"
import { CenterTitle, CenterText } from "../global/common/text"
import { EqualBox, UnstyledBoxes, BoxPoints } from "../case-study/common/boxes"

const DeliveryBox = styled(EqualBox)`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 25%;
  padding: 3rem;

  @media ${maxD.laptop} {
    width: 50%;
  }

  @media ${maxD.tablet} {
    width: 100%;
  }
`

const DeliveryPoints = styled(BoxPoints)`
  margin: 0 0 2rem;
`

const Delivery = ({ data }) => {
  const { deliveryTitle: title, deliveryList: list } = data

  return (
    <LineWrapperWithBottomSpace
      style={{
        "--does-bold-line-exists": "none",
      }}
    >
      <CenterTitle
        dangerouslySetInnerHTML={{ __html: title }}
        style={{
          "--padding-top": "1rem",
        }}
      />
      <UnstyledBoxes>
        {list &&
          list.map(
            ({
              offerDeliveryNumber: number,
              offerDeliveryDescription: description
            }, i) => (
              <DeliveryBox key={`delivery-${i}`}>
                <DeliveryPoints>{number}</DeliveryPoints>
                <CenterText dangerouslySetInnerHTML={{ __html: description }} />
              </DeliveryBox>
            )
          )}
      </UnstyledBoxes>
    </LineWrapperWithBottomSpace>
  )
}

export default Delivery
