import React from "react"
import styled from "styled-components"

import { UnstyledList } from "./common/essentials"
import { SecondaryTitle } from "./common/text"

import toSlug from "../../helpers/toSlug"

import SiteLanguage from "../../helpers/siteLanguage"
import optimizedPath from "../../helpers/optimizedPath"
import getImageBySrcSetAndSize from "../../helpers/getImageBySrcSetAndSize"
import getRangedSrcSet from "../../helpers/getRangedSrcSet"

const PointsContainer = styled(UnstyledList)`
  width: 100%;
  border: 1px solid var(--border-green);
`

const Point = styled.li`
  padding: 2.5rem 2rem;

  background-color: var(--point-background-color, transparent);

  &:not(:last-of-type) {
    border-bottom: 1px solid var(--border-green);
  }
`

const PointLogos = styled.img`
  max-width: 100%;
  max-height: 28px;

  margin-top: 1.8rem;
`

const Points = ({ style, points }) => (
  <PointsContainer style={style}>
    {points &&
      points.map((point, i) => {
        const title = point?.csImportantPointsSubTitle
        const description = point?.csImportantPointsDescription
        const slug = toSlug(title)

        const imageBase = point?.csImportantPointsImage

        const image = {
          url: getImageBySrcSetAndSize(imageBase?.srcSet, 'medium') ? getImageBySrcSetAndSize(imageBase?.srcSet, 'medium') : optimizedPath(imageBase?.sourceUrl),
          srcSet: getRangedSrcSet(imageBase?.srcSet, 'medium'),
          alt: (SiteLanguage() !== "en" && imageBase?.atttachement_pl?.altTextPl) ? imageBase?.atttachement_pl?.altTextPl : imageBase?.altText
        }

        return (
          <Point key={`${i}-${slug}`}>
            <SecondaryTitle
              dangerouslySetInnerHTML={{
                __html: title,
              }}
              style={{ "--spacing-bottom": "18px" }}
            />
            <div dangerouslySetInnerHTML={{ __html: description }}></div>
            {image.url && <PointLogos src={image.url} srcSet={image.srcSet} alt={image.alt} />}
          </Point>
        )
      })}
  </PointsContainer>
)

export default Points
