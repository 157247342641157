import React from "react"
import styled from "styled-components"

import { minD, betweenD, maxD } from "../../assets/styles/helpers/devices"
import { isLinkAnAnchor } from "../../assets/styles/helpers/common"

import Arrow from "../../assets/images/svg/right-arrow.svg"

import { LineWrapper, ShadowBoxContainer } from "../global/common/containers"
import { MainTitle, CenterTitle, SecondaryTitle } from "../global/common/text"
import { Button } from "../global/atoms/Button"
import { ImageLink, UnderlinedLink } from "../global/common/links"
import { ClientLogo } from "../global/common/logos"
import { Overlay } from "../global/common/ornaments"

import SiteLanguage from "../../helpers/siteLanguage"
import optimizedPath from "../../helpers/optimizedPath"
import getImageBySrcSetAndSize from "../../helpers/getImageBySrcSetAndSize"
import getRangedSrcSet from "../../helpers/getRangedSrcSet"

const Service = styled.section`
  padding-bottom: var(--section-spacing);
`

const ServiceGreenBox = styled(ShadowBoxContainer)`
  &::before {
    width: 50vw;
    height: calc(100% + var(--section-spacing) * 0.1);

    top: calc(var(--section-spacing) * -0.6);
    left: calc((-100vw + var(--wrapper-width)) / 2);

    z-index: -1;

    @media ${maxD.tabletL} {
      content: none;
    }
  }
`

const ServiceInner = styled.div`
  display: flex;
  flex-wrap: wrap;

  & > * {
    width: var(--desktop-column-width);

    @media ${betweenD.tabletLLaptop} {
      &:first-child {
        width: 100%;
      }

      &:not(:first-child) {
        width: var(--mobile-column-width);
      }
    }

    @media ${maxD.tabletL} {
      width: 100%;
    }
  }
`

const ServicePrice = styled(SecondaryTitle)`
  @media ${minD.laptop} {
    margin-top: 2rem;
  }

  @media ${maxD.laptop} {
    padding-bottom: 2rem;

    p + & {
      padding-top: 2rem;
    }
  }
`

const ServiceIntro = styled.article`
  @media ${minD.laptop} {
    padding-right: 3rem;
  }
`

const ServiceList = styled.article`
  padding: 3rem 3rem calc(3rem - 30px + 10px);

  border: 1px solid var(--border-green);
  background-color: #fff;

  @media ${maxD.mobileL} {
    padding: 30px;
  }
`

const CaseStudy = styled(ImageLink)`
  &::after {
    content: "";
    position: absolute;

    width: 100%;
    height: 100%;
    z-index: 1;

    background-color: rgba(0, 0, 0, 0.3);
    pointer-events: none;
  }

  @media ${maxD.tabletL} {
    height: min(400px, 84vw);
  }
`

const CaseStudyContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  width: 100%;
  height: 100%;
  z-index: 10;

  padding: 3rem;
  pointer-events: none;
`

const ButtonContainer = styled.footer`
  display: flex;
  justify-content: center;

  padding-top: calc(var(--section-spacing) * 0.38);
`

const HowMuch = ({ data }) => {
  const { howMuchTitle: title, costs } = data

  return (
    <LineWrapper>
      <CenterTitle
        dangerouslySetInnerHTML={{ __html: title }}
        style={{ "--padding-bottom": "4.8rem" }}
      />
      {costs &&
        costs.map(
          (
            {
              title,
              textUnderTitle,
              price,
              howMuchSectionCostList: listData,
              caseStudy: caseStudyData,
              button: buttonData,
            },
            i
          ) => {
            const list = {
              title: listData?.title,
              content: listData?.content,
            }

            const button = {
              title: buttonData?.title,
              url: buttonData?.url,
              target: buttonData?.target,
            }

            const caseStudy = {
              image: {
                url: getImageBySrcSetAndSize(caseStudyData?.featuredImage?.node.srcSet) ? getImageBySrcSetAndSize(caseStudyData?.featuredImage?.node.srcSet) : optimizedPath(caseStudyData?.featuredImage?.node.sourceUrl),
                srcSet: getRangedSrcSet(caseStudyData?.featuredImage?.node.srcSet),
                alt: (SiteLanguage() !== "en" && caseStudyData?.featuredImage?.node.atttachement_pl?.altTextPl) ? caseStudyData?.featuredImage?.node.atttachement_pl?.altTextPl : caseStudyData?.featuredImage?.node.altText
              },
              logo: {
                url: getImageBySrcSetAndSize(caseStudyData?.acfCaseStudy.whiteLogo?.srcSet, 'medium') ? getImageBySrcSetAndSize(caseStudyData?.acfCaseStudy.whiteLogo?.srcSet, 'medium') : optimizedPath(caseStudyData?.acfCaseStudy.whiteLogo?.sourceUrl),
                srcSet: getRangedSrcSet(caseStudyData?.acfCaseStudy.whiteLogo?.srcSet, 'medium'),
                alt: (SiteLanguage() !== "en" && caseStudyData?.acfCaseStudy.whiteLogo?.atttachement_pl?.altTextPl) ? caseStudyData?.acfCaseStudy.whiteLogo?.atttachement_pl?.altTextPl : caseStudyData?.acfCaseStudy.whiteLogo?.altText
              },
              url: caseStudyData?.uri,
            }

            return (
              <Service>
                <ServiceInner
                  style={
                    caseStudy.url
                      ? {
                          "--desktop-column-width": "33.33%",
                          "--mobile-column-width": "50%",
                        }
                      : {
                          "--desktop-column-width": "50%",
                          "--mobile-column-width": "100%",
                        }
                  }
                >
                  <ServiceIntro>
                    <MainTitle
                      as="h3"
                      dangerouslySetInnerHTML={{ __html: title }}
                      style={{ "--padding-bottom": "1.2rem" }}
                    />
                    {textUnderTitle && (
                      <p dangerouslySetInnerHTML={{ __html: textUnderTitle }} />
                    )}
                    <ServicePrice dangerouslySetInnerHTML={{ __html: price }} />
                  </ServiceIntro>
                  <ServiceList>
                    <SecondaryTitle
                      dangerouslySetInnerHTML={{ __html: list.title }}
                      style={{ marginBottom: "1rem" }}
                    />
                    <article
                      dangerouslySetInnerHTML={{ __html: list.content }}
                    />
                  </ServiceList>
                  {caseStudy.url && (
                    <CaseStudy
                      to={caseStudy.url}
                      anchor={isLinkAnAnchor(caseStudy.url)}
                    >
                      {caseStudy.image.url && (
                        <>
                          <Overlay
                            src={caseStudy.image.url}
                            srcSet={caseStudy.image.srcSet}
                            alt={caseStudy.image.alt}
                          />
                          <CaseStudyContent>
                            <ClientLogo
                              src={caseStudy.logo.url}
                              srcSet={caseStudy.logo.srcSet}
                              alt={caseStudy.logo.alt}
                            />
                            <UnderlinedLink
                              to={SiteLanguage() === "en" ? "https://bravenew.agency/case-studies" : "https://bravenew.agency/pl/case-studies-nasze-projekty"}
                              style={{ "--link-color": "#fff" }}
                            >
                              { SiteLanguage() === "en" ? "Check example" : "Zobacz przykład" }
                              <Arrow />
                            </UnderlinedLink>
                          </CaseStudyContent>
                        </>
                      )}
                    </CaseStudy>
                  )}
                </ServiceInner>
                <ButtonContainer>
                  {button && button.url && (
                    <Button
                      to={button.url}
                      target={button.target}
                      theme="orange"
                    >
                      {button.title}
                    </Button>
                  )}
                </ButtonContainer>
                {i % 2 === 1 && <ServiceGreenBox />}
              </Service>
            )
          }
        )}
    </LineWrapper>
  )
}

export default HowMuch
